<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="安装工人">
          <el-input v-model="searchForm.userName" autocomplete="off" size="small" placeholder="请输入安装工人" clearable></el-input>
        </el-form-item>
        <el-form-item label="行政区域">
          <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="statList" stripe style="width: 100%" height="100%">
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyName" label="安装单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="username" label="安装工人" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="count" label="录入单位数量" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-button type="text" size="default" @click="open('unit',scope.row.userId)">{{scope.row.count}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="photoCount" label="拍摄照片单位数量" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="locCount" label="定位单位数量" show-overflow-tooltip align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="录入单位详情" :visible.sync="unitShow" width="55%" class="unit-list" @close="searchForm2={}">
      <div class="container noangle">
        <div class="header">
          <el-form :inline="true" :model="searchForm2" class="demo-form-inline">
            <el-form-item label="是否定位">
              <el-select v-model="searchForm2.locationStatus" placeholder="请选择是否定位" clearable size="small" style="width:150px">
                <el-option label="已定位" :value="1"></el-option>
                <el-option label="未定位" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否拍照">
              <el-select v-model="searchForm2.photoStatus" placeholder="请选择是否拍照" clearable size="small" style="width:150px">
                <el-option label="已拍照" :value="1"></el-option>
                <el-option label="未拍照" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单位类别">
              <el-select v-model="searchForm2.typeId" placeholder="请选择单位类别" clearable size="small" style="width:150px">
                <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="initUnit">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="unitList" stripe style="width: 100%" height="400">
            <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="legalName" label="法人" show-overflow-tooltip width="80">
            </el-table-column>
            <el-table-column prop="legalPhone" label="法人电话" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="unitNatureName" label="单位性质" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="industryName" label="所属行业" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleUnitChange" :current-page.sync="currentUnitPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="unitTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      statList: [],
      unitList: [],
      typeList: [],
      currentPage: 1,
      currentUnitPage: 1,
      total: 0,
      unitTotal: 0,
      searchForm: {},
      searchForm2: {},
      userId: '',
      unitShow: false,
      areaCodes: [],
      isLoad: false
    }
  },
  created () {
    this.initTable()
    this.getAreaList()
    this.getTypeList()
  },
  methods: {
    async initTable () {
      this.searchForm.pageNo = this.currentPage
      this.searchForm.pageSize = 15
      if (!this.searchForm.userName) {
        this.$set(this.searchForm, 'userName', null)
      }
      if (this.areaCodes.length > 0) {
        this.searchForm.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      const { data: result } = await this.$axios.get('/unit/countUnitByWorker', { params: this.searchForm })
      if (result.code === 200) {
        this.statList = result.data.result
        this.total = result.data.total
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    handleUnitChange (val) {
      this.currentUnitPage = val
      this.initUnit()
    },
    open (flag, row) {
      switch (flag) {
        case 'unit':
          this.userId = row
          this.initUnit()
          this.unitShow = true
          break
      }
    },
    async initUnit () {
      const data = {
        ...this.searchForm2
      }
      data.pageNo = this.currentUnitPage
      data.pageSize = 15
      data.userId = this.userId
      console.log(data)
      const { data: result } = await this.$axios.get('/unit/listWeb', { params: data })
      if (result.code === 200) {
        this.unitList = result.data.result
        this.unitTotal = result.data.total
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    // 获取类型列表
    async getTypeList () {
      const { data: result } = await this.$axios.get('/unit-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-list {
  ::v-deep .el-dialog__body {
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
